import 'bootstrap/dist/css/bootstrap.min.css'
import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    {/* <SEO title="Home" /> */}
  </Layout>
)

export default IndexPage
